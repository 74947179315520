.container-page {
    overflow: "visible";
    align-items: center;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.list-page {
    position: relative;
    overflow: "visible";
    align-items: center;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.carousel {
    padding: 16px;
}
